.highlighted, .highlighted * {
  color: #f9b712;
}

.white {
  color: white;
}

.small {
  font-size: 0.9em;
}

.smaller {
  font-size: 0.8em;
}

.smallest {
  font-size: 0.6em;
}

p.cta > a {
  background: #f9b200;
  color: #fff;
  padding: 0 1rem;
  border: 0;
  text-transform: uppercase;
  line-height: 45px;
  display: inline-block;
  text-decoration: none;

  &:hover {
    background-color: darken(#f9b200, 5%);
    color: white;
  }
}
a.button_shadow {
  color: black;
  font-weight: bold;
  border: 1px solid black;
  padding: 7px 26px;
  box-shadow: 5px 5px #f9b200;
  display: inline-block;
  &:hover {
    box-shadow: -5px -5px #f9b200;
    background-color: #000;
    border-color: #000;
    color: white;
  }
}

a.white {
    color:white!important;
    &:hover {
        color: white!important;
        text-decoration: underline;
    }
}

a.black {
    color:black!important;
    &:hover {
        color: black!important;
        text-decoration: underline;
    }
}
